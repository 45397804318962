<!-- Finder particular de Presupuestos Expedientes -->

<template>
  <div class="pres_exp_F">
    <!-- Base Finder -->
    <base_F
      v-if="schema"
      :schema="schema"
      :Entorno="Entorno"
      @onEvent="event_capture"
    >
    </base_F>
  </div>
</template>

<script>
import { mixinFinder } from "@/mixins/mixinFinder.js";
import plugs from "@/common/general_plugs";
const base_F = () => plugs.groute("base_F.vue", "base");

export default {
  mixins: [mixinFinder],
  components: { base_F },
  props: {
    schema_M: { type: Object, default: null },
    id: { type: String, default: "" },
    accion: { type: [Number, String], default: "" },
    edicion: { type: Boolean, default: false },
    Entorno_F: {
      type: Object,
      default: function() {
        return JSON.parse(JSON.stringify(this.$cfg.base.F));
      }
    }
  },

  data() {
    return {
      Entorno: this.Entorno_F,
      schema: null
    };
  },

  methods: {
    // gestor de eventos
    event_capture(evt) {
      console.log("*** onEvent pres_exp_F.vue. Accion: ", evt, " ***");

      if (this.$isFunction(this[evt.accion])) {
        this[evt.accion](evt);
        return;
      }
      this.$emit("onEvent", evt);
    },

    // configuro entorno Finder particular
    get_entorno_Finder() {
      this.set_btra();
      this.set_btraExtra();

      this.Entorno.api = "pres_exp_F";
      if (typeof this.Entorno.grid.headers.headers === "undefined") {
        this.Entorno.grid.headers = [
          { text: "T", value: "tipo_ope" },
          { text: "PRESUP.", value: "pre_doc" },
          { text: "FACTURA", value: "fra_doc" },
          { text: "IMPORTE", value: "imp" },
          { text: "CUENTA", value: "cuenta" },
          { text: "DESCRIPCIÓN", value: "descrip" },
          {
            text: "ACCIONES",
            value: "acciones",
            sortable: false,
            width: "auto"
          }
        ].map(header => ({ ...this.Entorno.grid.headers, ...header }));
      }

      this.Entorno.header.style += ";min-width:60rem";
    },

    //
    set_btraExtra() {}
  }
};
</script>
